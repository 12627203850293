import Vue from "vue";
import VueRouter from "vue-router";
import { IconUserSquare } from "@lde/core_lde_vue";
import { UilSearchAlt, UilPresentationPlay } from "@iconscout/vue-unicons";
import store from "@/store";
import i18n from "@/modules/i18n";
import routes from "@/router/routes";

import IconPanier from "@/components/icons/IconPanier.vue";

Vue.use(VueRouter);

function getUrls(trad) {
  const aliases = Object.keys(i18n.messages).map((local) => `/${i18n.t(trad, local)}`);
  return { path: `/${i18n.t(trad)}`, alias: aliases };
}

const router = new VueRouter({
  mode: "history",
  routes: [
    ...routes,
    {
      path: "/",
      name: "home",
      component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
    },
    {
      path: "/search",
      name: "search",
      component: () => import(/* webpackChunkName: "search" */ "@/views/ResultatsRecherche.vue"),
      meta: {
        title: i18n.t("recherche.resultats-de-recherche"),
        icon: UilSearchAlt,
      },
    },
    {
      ...getUrls("url.compte"),
      name: "compte",
      component: () => import(/* webpackChunkName: "compte" */ "@/views/Compte.vue"),
      meta: {
        title: i18n.t("menu.votre-profil"),
        icon: IconUserSquare,
      },
    },
    {
      ...getUrls("url.tutoriels"),
      name: "tutoriels",
      component: () => import(/* webpackChunkName: "tutoriels" */ "@/views/Tutoriels.vue"),
      meta: {
        title: i18n.t("footer.tutoriels"),
        icon: UilPresentationPlay,
        accessNotLogged: true,
      },
    },
    {
      ...getUrls("url.panier"),
      name: "panier",
      component: () => import(/* webpackChunkName: "Panier" */ "@/views/Panier.vue"),
      meta: {
        title: i18n.t("panier.mon-panier"),
        icon: IconPanier,
      },
    },
    {
      path: "/403",
      name: "403",
      component: () => import(/* webpackChunkName: "403" */ "@/views/Forbidden.vue"),
    },
    {
      path: "*",
      name: "404",
      component: () => import(/* webpackChunkName: "404" */ "@/views/NotFound.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta?.accessNotLogged) {
    next();
  } else if (!store.getters.isLogged) {
    store.dispatch("login").then(() => {
      if (to.meta?.permissions?.length > 0 && !store.getters.hasPerms(to.meta.permissions)) {
        next({ name: "403" });
      } else {
        next();
      }
    });
  } else if (to.meta?.permissions?.length > 0 && !store.getters.hasPerms(to.meta.permissions)) {
    next({ name: "403" });
  } else {
    next();
  }
});

export default router;
export { getUrls };
