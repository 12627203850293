<template>
  <div
    v-click-outside="closeSearchResults"
    v-focus-outside="closeSearchResults"
    class="search-form"
  >
    <SearchFormSelect
      v-if="options.length"
      :selected-option="selectedOption"
      :options="options"
      :disabled="disabled || showAdvSearch"
      @select="$emit('select-option', $event)"
      @toggle-list="closeSearchResults()"
      @close-input-dropdown="closeSearchResults"
    />
    <div
      class="input-wrapper flex-vcenter"
      :class="{ 'with-options': options.length, }"
    >
      <ButtonClassic
        id="open_adv_search"
        v-tooltip="{
          content: $t('recherche.recherche-avancee'),
          placement: 'bottom',
          delay: { show: 800, },
        }"
        variant="solid"
        icon="right"
        size="medium"
        :disabled="disabled"
        @click="toggleAdvSearch()"
      >
        <template
          #right-icon
        >
          <IconRechercheAvancee />
        </template>
      </ButtonClassic>
      <input
        v-model="searchTerm"
        class="search-form-input text-regular"
        type="text"
        :placeholder="placeholder"
        :disabled="disabled || showAdvSearch"
        @focus="isSearchInputFocused = true"
        @keydown="handleKeyDown($event)"
      />
      <ButtonClassic
        class="form-submit"
        color="primary"
        variant="solid"
        icon="left"
        :title="$t('recherche.lancer-la-recherche')"
        :disabled="disabled || showAdvSearch"
        @click="submitForm()"
      >
        <template #left-icon>
          <UilSearchAlt />
        </template>
      </ButtonClassic>
    </div>

    <div :class="{ 'hidden': !isSearchInputFocused, }">
      <div
        v-if="recentSearches.length && !searchTerm"
        class="options-list-container"
      >
        <h3 class="options-list-container-title">
          {{ $t('recherche.recherches-recentes') }}
        </h3>
        <ul
          class="text-medium"
          @keyup.esc="closeSearchResults()"
        >
          <li
            v-for="(recentSearch, index) in recentSearches"
            :key="'options-list-item-' + index"
            class="options-list-item"
          >
            <button
              type="button"
              class="options-list-item-btn"
              @click="submitForm(recentSearch)"
            >
              {{ recentSearch }}
            </button>
          </li>
        </ul>
      </div>
      <!-- @slot Emplacement pour les suggestions liées à la recherche. -->
      <slot
        v-else-if="showSuggestions"
        name="search-suggestions"
      />
    </div>
    <AdvancedSearch
      v-if="showAdvSearch"
      :quick-search-catalogue="selectedOption"
      @search="toggleAdvSearch()"
    />
  </div>
</template>

<script>
import { ButtonClassic, SearchFormSelect } from "@lde/core_lde_vue";
import { UilSearchAlt } from "@iconscout/vue-unicons";
import AdvancedSearch from "@/components/search/AdvancedSearch.vue";

import IconRechercheAvancee from "@/components/icons/IconRechercheAvancee.vue";

/**
 * Affiche un formulaire de recherche.
 */
export default {
  name: "SearchForm",
  components: {
    ButtonClassic,
    SearchFormSelect,
    UilSearchAlt,
    IconRechercheAvancee,
    AdvancedSearch,
  },
  model: {
    prop: "searchText",
    event: "change",
  },
  props: {
    /**
     * Texte de recherche.
     */
    searchText: {
      type: String,
      default: "",
    },
    /**
     * Désactive le formulaire de recherche.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Délais de process pour la recherche.
     */
    debounceDelay: {
      type: Number,
      default: 750,
    },
    /**
     * Affiche les suggestions (besoin du slot "search-suggestions")
     */
    showSuggestions: {
      type: Boolean,
      default: false,
    },
    /**
     * Catégories à afficher.
     */
    options: {
      type: Array,
      default: () => [],
    },
    /**
     * Si l'input de recherche est focus.
     */
    isInputFocused: {
      type: Boolean,
      default: false,
    },
    /**
     * Placeholder à mettre dans le input de la recherche.
     */
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * Option sélectionnée.
     */
    selectedOption: {
      type: Object,
      default: null,
    },
    /**
     * Option sélectionnée.
     */
    showAdvSearch: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    /**
     * Déclenché lorsque l'input de recherche change.
     */
    "change",
    /**
     * Déclenché lorsque l'input est focus.
     */
    "input-focused",
    /**
     * Déclenché lorsqu'on soumet la recherche.
     */
    "submit",
    /**
     * Déclenché lorsqu'on souhaite afficher les suggestions.
     */
    "fetch-suggestions",
    /**
     * Déclenché lorsqu'on sélectionne une option.
     */
    "select-option",
  ],
  data() {
    return {
      recentSearches: [],
      searchSuggestionsTimeout: null,
      suggestionIndex: null,
    };
  },
  computed: {
    searchTerm: {
      get() {
        return this.searchText;
      },
      set(newVal) {
        this.$emit("change", newVal);
      },
    },
    isSearchInputFocused: {
      get() {
        return this.isInputFocused;
      },
      set(newVal) {
        this.$emit("input-focused", newVal);
      },
    },
  },
  watch: {
    suggestionIndex(newVal, oldVal) {
      const buttons = document.querySelectorAll(".search-form li button");
      let suggestion = buttons[oldVal - 1];
      if (suggestion) {
        suggestion.parentElement.classList.remove("active");
      }

      if (newVal !== null) {
        suggestion = buttons[newVal - 1];
        if (suggestion) {
          suggestion.parentElement.classList.add("active");
        } else if (newVal < 1) {
          this.suggestionIndex = buttons.length;
        } else if (newVal > buttons.length) {
          this.suggestionIndex = 1;
        }
      }
    },
    $route: {
      handler() {
        if (this.$route.query.search) {
          // Remise en place de la recherche précédente si le paramètre existe.
          this.searchTerm = this.$route.query.search;
        } else {
          // Vider si on navigue vers une autre page.
          this.searchTerm = "";
        }
      },
    },
  },
  mounted() {
    // Récupération du contenu du le localStorage s'il y en a.
    const localRecentSearches = JSON.parse(localStorage.getItem("recentSearches"));
    if (Array.isArray(localRecentSearches)) {
      this.recentSearches = localRecentSearches;
    }
    if (this.$route.query.search) {
      // Remise en place de la recherche précédente si le paramètre existe.
      this.searchTerm = this.$route.query.search;
    }
  },
  methods: {
    /**
     * Ferme le dropdown des recherches récentes/suggestions.
     */
    closeSearchResults() {
      this.isSearchInputFocused = false;
    },
    /**
     * Effectue une recherche et l'enregistre dans les recherches précédentes.
     */
    submitForm(search) {
      if (search) {
        this.searchTerm = search;
      }
      if (this.searchTerm?.trim()) {
        this.saveSearch();
      }
      this.$emit("submit");
      this.closeSearchResults();
    },
    /**
     * Récupère des suggestions de recherche.
     */
    getSearchSuggestions() {
      this.suggestionIndex = null;

      if (this.searchTerm.length > 2) {
        if (this.searchSuggestionsTimeout) {
          clearTimeout(this.searchSuggestionsTimeout);
        }

        this.searchSuggestionsTimeout = setTimeout(() => {
          this.$emit("fetch-suggestions");
        }, this.debounceDelay);
      }
    },
    /**
     * Sauvegarde la recherche en local dans le composant et dans le localStorage.
     */
    saveSearch() {
      this.suggestionIndex = null;

      if (!this.recentSearches.includes(this.searchTerm)) {
        this.recentSearches.unshift(this.searchTerm);

        // On ne veut pas plus d 6 résultats
        if (this.recentSearches.length > 6) {
          this.recentSearches.pop();
        }
      }

      const localRecentSearches = JSON.parse(localStorage.getItem("recentSearches"));

      if (Array.isArray(localRecentSearches)) {
        if (!localRecentSearches.includes(this.searchTerm)) {
          localRecentSearches.unshift(this.searchTerm);

          if (localRecentSearches.length > 6) {
            localRecentSearches.pop();
          }
          localStorage.setItem("recentSearches", JSON.stringify(localRecentSearches));
        }
      } else {
        localStorage.setItem("recentSearches", JSON.stringify(this.recentSearches));
      }
    },
    /**
     * Permet de naviguer entre les précédentes recherches à l'aide des flèches haut et bas.
     * @param {Object} e Événement natif de l'input.
     */
    handleKeyDown(e) {
      // Si on appuie sur la flèche du bas (40) ou du haut (38)
      if (e.keyCode === 40 || e.keyCode === 38) {
        this.suggestionIndex += (e.keyCode === 40 ? 1 : -1);
      } else if (e.keyCode === 13) {
        if (this.suggestionIndex) {
          document.querySelectorAll(".search-form li button")[this.suggestionIndex - 1].click();
        } else {
          e.target.blur();
          this.submitForm();
        }
      } else {
        this.getSearchSuggestions();
      }
    },
    /**
     * Bascule entre la recherche avancée et la recherche classique.
     */
    toggleAdvSearch() {
      this.isSearchInputFocused = false;
      this.$store.dispatch("toggleAdvSearch");
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/search/search_form.scss";
</style>
