import Vue from "vue";
import Moment from "moment";
import Api from "@/modules/axios";
import i18n from "@/modules/i18n";

export default {
  state: {
    organismeActuel: null,
    isHorsMarche: false,
    exportingStatsMarche: localStorage.getItem("dateDemandeExportStatsMarche"),
  },
  getters: {
    organismeActuel(state) {
      return state.organismeActuel;
    },
    isHorsMarche(state) {
      return state.isHorsMarche;
    },
    isSpecificMarche: (state) => (marche) => state.organismeActuel?.marches?.some(
      ({ libelle }) => libelle.includes(marche),
    ),
    isGE(state, getters) {
      return getters.isSpecificMarche("Grand Est");
    },
    isMarcheDemo(state) {
      return state.organismeActuel?.maitre_compta === "20C0127";
    },
    isIDF(state, getters) {
      return getters.isSpecificMarche("Ile-de-France");
    },
    montrerModalAV(state, getters) {
      return getters.isMarcheDemo || getters.isGE || getters.isReunion;
    },
    isReunion(state) {
      return state.organismeActuel.maitre_compta.toLowerCase() === "23c3991";
    },
    compatibleAdoptant(state, getters) {
      // console.log(getters.isGE, getters.isGMBH);
      return getters.isGE || getters.isGMBH;
    },
    isSharedBudget(state, getters) {
      return getters.organismeActuel.catalogues_actifs.some((cat) => [2, 3].includes(cat.id_4d));
    },
    hasMail(state) {
      return state.organismeActuel?.marches?.some(({ email }) => email);
    },
    /**
     * Si la demande a été faite il y a moins d'un jour, on renvoie true.
     * @param {Object} state Contient le state.
     * @returns {Boolean}
     */
    exportingStatsMarche(state) {
      return state.exportingStatsMarche && Moment().diff(Moment(state.exportingStatsMarche), "days") < 1;
    },
  },
  mutations: {
    setOrganismeActuel(state, organismeActuel) {
      state.organismeActuel = organismeActuel;
      // Si on n'a pas de marché, on est dans le hors-marché
      if (organismeActuel) {
        state.isHorsMarche = organismeActuel.marches && !organismeActuel.marches.length;
        localStorage.setItem("current_organisme_id", organismeActuel.id);
      } else {
        state.isHorsMarche = false;
        localStorage.setItem("current_organisme_id", null);
      }
    },
    setImgEtablissement(state, { image, image_from_library: lib }) {
      state.organismeActuel.infos.image = image;
      state.organismeActuel.infos.image_from_library = lib;
    },
    setExportingStatsMarche(state) {
      state.exportingStatsMarche = Moment().format();
      localStorage.setItem("dateDemandeExportStatsMarche", state.exportingStatsMarche);
    },
  },
  actions: {
    /**
     * Récupère les organismes accessibles.
     * @param {String} searchText Texte tapé dans l'input.
     */
    fetchOrganismes(store, searchText = "") {
      return new Promise((resolve, reject) => {
        Api().get(`/organisme/?search=${searchText}`)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => reject(e));
      });
    },
    /**
     * Vérifie et met à jour le SIRET de l'organisme.
     * @param {Object} - ID et SIRET de l'organisme.
     */
    updateSiret(store, { idOrganisme, siret }) {
      return new Promise((resolve, reject) => {
        Api().post(`organisme/${idOrganisme}/set_siret/`, { siret })
          .then((res) => {
            store.commit("setOrganismeActuel", res.data);
            resolve(res);
          })
          .catch((e) => { reject(e); });
      });
    },
    /**
     * Envoie une demande d'export XLS des statistiques marché.
     */
    askExportStatsMarche(store) {
      return new Promise((resolve, reject) => {
        Api().post("/organisme/ask_export_stats/")
          .then(({ data }) => {
            if (data.ok) {
              store.commit("setExportingStatsMarche");
              Vue.$toast.success({
                title: i18n.t("etablissement.demande-export-bien-ete-transmise"),
                content: i18n.t("etablissement.vous-recevrez-statistiques-marche-24-h"),
              });
              resolve(data);
            } else {
              Vue.$toast.error({ title: i18n.t("etablissement.mail-pas-pu-etre-envoye") });
              reject();
            }
          })
          .catch((e) => {
            Vue.$toast.error({ title: i18n.t("etablissement.mail-pas-pu-etre-envoye") });
            reject(e);
          });
      });
    },
  },
};
