/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import Vue, { CreateElement } from "vue";
import type { Component } from "vue";
import VueMatomo from "vue-matomo";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/de";

import CoreLdeVue from "@lde/core_lde_vue";

import { Store } from "vuex";
import i18n from "@/modules/i18n";
import matomoConfig, { matomoLogClickDirective, handleMatomoBlocked } from "@/modules/matomo";

import router from "@/router/index.js";
import store from "@/store/index.js";
import App from "./App.vue";

moment.locale("fr");
moment.locale("de");

// Importe tous les composants génériques et les plugins de CoreLdeVue
Vue.use(CoreLdeVue);

Vue.use(VueMatomo, matomoConfig);
if (!Vue.prototype.$matomo) {
  handleMatomoBlocked(Vue);
}

Vue.directive("matomo-log-click", matomoLogClickDirective);

Vue.mixin({
  computed: {
    isImpersonating(): boolean {
      return ((this as Component).$store as Store<any>).getters.impersonating as boolean;
    },
  },
});

// =========== Gestion de la mise à jour du cache via WS ===========

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration: ServiceWorkerRegistration) => {
        registration.addEventListener("updatefound", () => {
          // Si un nouveau Service Worker est en attente
          if (registration.installing) {
            const newWorker = registration.installing;

            newWorker.addEventListener("statechange", () => {
              if (newWorker.state === "installed" && registration.waiting) {
                // Un nouveau Service Worker est disponible, forcer à rafraîchir la page
                caches.keys().then((cacheNames: string[]) => {
                  cacheNames.forEach((cacheName: string) => {
                    caches.delete(cacheName).catch((error: Error) => {
                      console.error("Erreur lors de la suppression du cache : ", error);
                    });
                  });
                }).catch((error: Error) => {
                  console.error("Erreur lors de la récupération du cache : ", error);
                });

                registration.waiting?.postMessage({ action: "skipWaiting" });
              }
            });
          }
        });
      }).catch((error: Error) => {
        console.error("Erreur lors de l'enregistrement du Service Worker : ", error);
      });
  });

  navigator.serviceWorker.addEventListener("controllerchange", () => {
    window.location.reload();
  });
}

// =======================================================

export default new Vue({
  name: "Main",
  router,
  store,
  i18n,
  render: (h: CreateElement) => h(App),
}).$mount("#app");
