import { mapGetters } from "vuex";
import config from "@/config";

// C'est relou de devoir mettre api-.../admin à chaque fois, on va refaire un semblant de konami code
// pour ouvrir directement la page admin
// Flèche gauche, bas, droite en même temps
export default {
  data() {
    return {
      keys: {
        ArrowLeft: false,
        ArrowDown: false,
        ArrowRight: false,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keyup", this.handleKeyUp);
  },
  beforeDestroy() {
    // Nettoyer les écouteurs d'événements lorsque le composant est détruit
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  methods: {
    handleKeyDown(event) {
      if (event.key in this.keys) {
        this.keys[event.key] = true;

        // Vérifier si les trois touches sont enfoncées simultanément
        if (this.keys.ArrowLeft && this.keys.ArrowDown && this.keys.ArrowRight) {
          // Action à exécuter
          this.triggerAction();
          this.keys = {
            ArrowLeft: false,
            ArrowDown: false,
            ArrowRight: false,
          };
        }
      }
    },
    handleKeyUp(event) {
      if (event.key in this.keys) {
        this.keys[event.key] = false;
      }
    },
    triggerAction() {
      if (this.user.role === "Opérateur LDE") {
        window.open(`${config.backUrl}/admin/synchronisation/configuration/?o=5.1`, "_blank");
      }
    },
  },
};
