<template>
  <div
    class="button-group-header"
    :class="{ 'dark-mode': darkMode, }"
  >
    <ButtonGroup>
      <ButtonClassic
        v-if="hasPerm('view_liste')"
        id="bouton_panier"
        v-tooltip="{
          content: $tc(
            'panier.x-selection-dans-panier',
            panier?.quantite_ligne,
            { selection: panier?.quantite_ligne, }
          ),
          placement: 'bottom',
          delay: { show: 800, },
        }"
        variant="solid"
        color="secondary"
        size="medium"
        @click="$router.push({ name: 'panier', })"
      >
        <IconPanier
          height="16"
          width="16"
        />
        <InfiniteLoader v-if="isFetchingPanier" />
        <Counter
          v-if="linesInPanier > 0 && !isFetchingPanier"
          :value="spaceNumber(linesInPanier)"
          size="small"
        />
      </ButtonClassic>
    </ButtonGroup>
    <ModalListesCreation @open-quick-list="listName = $event" />
    <ModalListesSaisieRapide :list-name="listName" />
  </div>
</template>

<script>
import {
  ButtonClassic,
  ButtonGroup,
  Counter,
  InfiniteLoader,
  spaceNumber,
} from "@lde/core_lde_vue";

import { mapGetters } from "vuex";
import ModalListesCreation from "@/components/modals/ModalListesCreation.vue";
import ModalListesSaisieRapide from "@/components/modals/ModalListesSaisieRapide.vue";

import IconPanier from "@/components/icons/IconPanier.vue";

/**
 * Groupe de boutons dans la topbar à gauche de l'avatar.
 */
export default {
  name: "ButtonGroupHeader",
  components: {
    ButtonGroup,
    ButtonClassic,
    Counter,
    ModalListesCreation,
    ModalListesSaisieRapide,
    InfiniteLoader,
    IconPanier,
  },
  props: {
    /**
     * La couleur de fond du parent est sombre.
     */
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPopoverOpen: false,
      listName: "",
    };
  },
  computed: {
    ...mapGetters([
      "panier",
      "isFetchingPanier",
      "preferences",
      "isHorsMarche",
      "hasPerm",
      "organismeActuel",
    ]),
    isTotalInBar: {
      get() {
        return this.preferences?.totalInBar;
      },
      set(totalInBar) {
        this.$store.commit("updatePreferences", { totalInBar });
      },
    },
    linesInPanier() {
      return this.panier?.quantite_ligne;
    },
    tooltipTitle() {
      if (this.panier) {
        return this.$tc(
          "selection.x-selection-en-cours",
          spaceNumber(this.linesInPanier),
          { selection: spaceNumber(this.linesInPanier) },
        );
      }
      return this.$t("selection.aucune-liste-selectionnee");
    },
  },
  methods: {
    spaceNumber,
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/buttons/button_group_header.scss";
</style>
