import Vue from "vue";
import VueI18n from "vue-i18n";
import { fr as cfr, de as cde } from "@lde/core_lde_vue/dist/modules/i18n";
import { removeEmptyTradKeys } from "@lde/core_lde_vue/dist/modules/utils";
import store from "@/store";
import { getBrowserLocale, supportedLocalesInclude } from "@/modules/utils";

const fr = require("@/locales/fr.json");
const de = require("@/locales/de.json");

// Pour fusionner deux objets en profondeur.
const merge = (target, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  Object.keys(source).forEach((key) => {
    if (source[key] instanceof Object) {
      Object.assign(source[key], merge(target[key], source[key]));
    }
  });

  // Join `target` and modified `source`
  Object.assign(target || {}, source);
  return target;
};

Vue.use(VueI18n);

let locale = getBrowserLocale({ countryCodeOnly: true });
if (!supportedLocalesInclude(locale)) {
  locale = process.env.VUE_APP_I18N_LOCALE || (store.getters.isGMBH ? "de" : "fr");
}

export default new VueI18n({
  locale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "fr",
  silentTranslationWarn: true, // N'affiche pas les messages "warn" quand les traductions manquent
  messages: {
    fr: merge(removeEmptyTradKeys(fr), cfr),
    de: merge(removeEmptyTradKeys(de), cde),
  },
});
