<template>
  <form
    v-focus-outside="searchFormUnfocus"
    class="advanced-search"
  >
    <ul>
      <li class="adv-search-block">
        <h2 class="options-list-container-title">
          {{ $t("recherche.recherche-avancee") }}
        </h2>
      </li>
      <li class="adv-search-block">
        <label class="text-medium">
          {{ $t("recherche.rechercher-dans") }}
        </label>
        <SelectClassic
          v-model="currentCatalogue"
          status="required"
          class="search-param"
          :options="searchCatalogues"
          inline
          select-on-enter
          @select="clearSearch()"
        />
      </li>
      <li
        v-for="(currentSearchOption, index) in currentSearch"
        :key="currentSearchOption.id"
        class="adv-search-block text-medium"
      >
        <label
          class="text-medium"
        >
          {{ $t("recherche.et-par") }}
        </label>
        <SelectClassic
          v-model="currentSearchOption.param"
          name="searchParams"
          class="search-param"
          :options="availableOptions"
          select-on-enter
          inline
          @select="addLine(index)"
        />

        <SelectClassic
          v-if="currentSearchOption.param?.choices"
          v-model="currentSearchOption.term"
          class="search-term"
          :options="currentSearchOption.param?.choices"
          select-on-enter
          inline
        />
        <InputClassic
          v-else
          v-model="currentSearchOption.term"
          class="search-term"
          variant="solid"
          inline
          :placeholder="$t('recherche.renseigner')"
          @keyup.enter.native="submitForm()"
        />

        <ButtonClassic
          v-if="currentSearchOption.param"
          variant="solid"
          color="primary"
          icon="left"
          size="small"
          @click="deleteLine(index)"
        >
          <template #left-icon>
            <UilTrashAlt />
          </template>
        </ButtonClassic>
      </li>
      <li class="adv-search-block">
        <ButtonClassic
          class="adv-search-button"
          color="primary"
          variant="solid"
          icon="left"
          :label="$t('recherche.lancer-la-recherche')"
          :disabled="!canSearch"
          @click="submitForm()"
        >
          <template #left-icon="{ iconSize, }">
            <UilSearchAlt :size="iconSize" />
          </template>
        </ButtonClassic>
      </li>
    </ul>
  </form>
</template>

<script>
import { ButtonClassic, SelectClassic, InputClassic } from "@lde/core_lde_vue";
import { UilTrashAlt, UilSearchAlt } from "@iconscout/vue-unicons";
import { mapGetters } from "vuex";
import Api from "@/modules/axios";

export default {
  name: "AdvancedSearch",
  components: {
    SelectClassic,
    InputClassic,
    ButtonClassic,
    UilSearchAlt,
    UilTrashAlt,
  },
  props: {
    /**
     * Catalogue sélectionnée dans la barre de recherche rapide.
     */
    quickSearchCatalogue: {
      type: Object,
      default: null,
    },
  },
  emits: [
    /**
     * Déclenché au lancement de la recherche.
     */
    "search",
  ],
  data() {
    return {
      currentSearch: [],
      currentCatalogue: {},
      choices: {},
      searchObject: {
        param: null,
        term: null,
      },
    };
  },
  computed: {
    ...mapGetters(["hasPerm", "advancedSearchActive"]),
    canSearch() {
      return this.currentSearch.every((choice) => {
        if (choice.param && choice.term) {
          return true;
        }
        if (!choice.param && this.currentSearch.length > 1) {
          return true;
        }
        return false;
      });
    },
    searchCatalogues() {
      const cat = [];
      if (this.hasPerm("view_articlepapier")) {
        cat.push({
          label: this.$t("menu.livres-et-manuels-papier"),
          value: "papiers",
        });
      }
      if (this.hasPerm("view_manuelnumerique")) {
        cat.push({
          label: this.$t("menu.ressources-numeriques"),
          value: "numeriques",
        });
      }
      if (this.hasPerm("view_fourniture")) {
        cat.push({
          label: this.$t("menu.fournitures-et-papeterie"),
          value: "fournitures",
        });
      }
      return cat;
    },
    advancedSearchOptions() {
      const options = {
        papiers: [
          { label: this.$t("general.titre"), value: "libelle" },
          { label: this.$t("produit.auteur"), value: "auteurs" },
          { label: this.$t("produit.editeur"), value: "editeur" },
          { label: this.$t("produit.langue"), value: "langue", choices: this.choices.langues_pap },
          { label: this.$t("produit.theme"), value: "themes", choices: this.choices.themes_pap },
          { label: this.$t("produit.annee-edition"), value: "date_publication", choices: this.choices.annee_pap },
        ],
        numeriques: [
          { label: this.$t("general.titre"), value: "libelle" },
          { label: this.$t("produit.editeur"), value: "editeur" },
          { label: this.$t("general.type-etablissement"), value: "niveaux", choices: this.choices.niveaux_num },
          { label: this.$t("produit.discipline"), value: "matieres" },
          { label: this.$t("produit.classe"), value: "classes", choices: this.choices.classes_num },
          { label: this.$t("produit.annee-edition"), value: "date_publication", choices: this.choices.annee_num },
        ],
        fournitures: [
          { label: this.$t("produit.marque"), value: "marque" },
          { label: this.$t("general.designation"), value: "libelle" },
          { label: this.$t("produit.couleur"), value: "coloris" },
        ],
      };
      return this.currentCatalogue.value ? options[this.currentCatalogue.value] : [];
    },
    availableOptions() {
      return this.advancedSearchOptions.filter((option) => {
        const isOptionChosen = this.currentSearch.some(
          (currentOption) => currentOption?.param?.label === option.label,
        );
        return !isOptionChosen;
      });
    },
  },
  mounted() {
    this.currentSearch.push({ ...this.searchObject, id: Date.now() });
    this.getAdvancedOptions();
  },
  methods: {
    /**
     * Met en place les données pour les choix de la recherche avancée.
     */
    getAdvancedOptions() {
      Api().get("/recherche/advanced_choices/")
        .then(({ data }) => {
          this.choices = data;
          this.setSearch();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * Remet en place la recherche précédente si dans l'url le parametre "advanced" est true.
     */
    setSearch() {
      const query = this.$route.query;
      const adv = query.advanced;
      const cat = query.only;
      if (adv) {
        this.currentCatalogue = this.searchCatalogues.find((obj) => obj.value.includes(cat));
        this.currentSearch = [];
        Object.entries(query).forEach(([param, term]) => {
          if (param.includes("_adv_")) {
            const opt = param.replace(`${query.only}_adv_`, "");
            const searchOption = {
              param: this.advancedSearchOptions.find((obj) => obj.value === opt),
            };
            if (searchOption.param.choices) {
              // Il y a parfois un {Objet} choices et pas simplement du texte.
              searchOption.term = searchOption.param.choices.find((obj) => obj.value === term);
            } else {
              searchOption.term = term;
            }
            this.currentSearch.push(searchOption);
          }
        });
        this.currentSearch.push({ ...this.searchObject, id: Date.now() });
      } else {
        const quickCat = this.searchCatalogues.find((obj) => obj.value.includes(this.quickSearchCatalogue.name));
        this.currentCatalogue = quickCat || this.searchCatalogues[0];
      }
    },
    /**
     * Met une ligne vide à la fin de la recherche avancée.
     * @param {Integer} index Position du tableau à réinitialiser.
     */
    addLine(index) {
      const emptyLines = this.currentSearch.some((choice) => !choice.param);
      if (!emptyLines && this.currentSearch.length < this.advancedSearchOptions.length) {
        this.currentSearch.push({ ...this.searchObject, id: Date.now() });
      }
      this.currentSearch[index].term = null;
    },
    /**
     * Supprime une ligne de la recherche avancée.
     * @param {Integer} index Position du tableau à supprimer.
     */
    deleteLine(index) {
      const emptyLines = this.currentSearch.some((choice) => !choice.param);
      if (this.currentSearch.length === 1) {
        this.currentSearch = [{ ...this.searchObject, id: Date.now() }];
      } else if (!emptyLines && this.currentSearch.length === this.advancedSearchOptions.length) {
        this.currentSearch.splice(index, 1, { ...this.searchObject, id: Date.now() });
      } else {
        this.currentSearch.splice(index, 1);
      }
    },
    /**
     * Vide la recherche avancée en cours.
     */
    clearSearch() {
      this.currentSearch = [{ ...this.searchObject, id: Date.now() }];
    },
    /**
     * Soumet la recherche avancée en cours et pousse vers la vue de recherche.
     */
    submitForm() {
      if (this.canSearch) {
        const query = {
          advanced: true,
          only: this.currentCatalogue.value,
        };
        this.currentSearch.forEach((choice) => {
          if (choice.param && choice.term) {
            const param = `${this.currentCatalogue.value}_adv_${choice.param.value}`;
            query[param] = choice.term?.value || choice.term;
          }
        });

        this.$router
          .push({
            name: "search",
            query: {
              ...query,
            },
          });
        this.$emit("search");
        if (this.$route.name === "search") {
          // On rafraîchit la page
          this.$store.commit("addSearchKey");
        }
      }
    },
    /**
     * Ferme le formulaire de recherche avancée si on clique en dehors du dropdown.
     * On doit exclure le bouton (open_adv_search) sinon on réouvre à nouveau le menu.
     */
    searchFormUnfocus(event) {
      if (event.target.id !== "open_adv_search") {
        this.$store.dispatch("toggleAdvSearch");
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/search/advanced_search.scss";
</style>
