<template>
  <Modal id="modal_verify_account">
    <template #title>
      <span v-if="isSpecificIDF">
        ATTENTION nouveauté 2024
      </span>
      <span
        v-else
        v-html="$t('general.attention')"
      />
    </template>
    <template #content>
      <p
        v-if="isSpecificIDF"
        class="text-regular message"
      >
        Toute commande sur ce compte se fera sur les fonds propres de l'établissement.
        <br /><br />
        Pour votre dotation régionale, rendez-vous sur Ressources.num grâce à vos accès
        <a
          href="https://monlycee.net/"
          target="_blank"
        >
          MonLycée.net
        </a>
      </p>
      <p
        v-else
        class="text-regular message"
        v-html="typeMarcheTraduction().verifCompteTouteCommandeSurFondsPropresAvertissement"
      />
      <div
        v-if="!isExcluded"
        class="select-org-container"
      >
        <SelectOrganismeInput
          v-model="selectedOrganisme"
          :options="organismeMarches"
        />
      </div>
    </template>
    <template #footer>
      <ButtonClassic
        variant="solid"
        :label="isExcluded ? $t('action.continuer') : $t('action.continuer-sans-changer')"
        color="primary"
        @click="$modal.hide('modal_verify_account')"
      />
      <ButtonClassic
        v-if="!isExcluded"
        variant="solid"
        :label="$t('general.changer-compte')"
        color="secondary"
        @click="confirm()"
      />
    </template>
  </Modal>
</template>

<script>
import { ButtonClassic, Modal } from "@lde/core_lde_vue";

import { mapGetters } from "vuex";
import SelectOrganismeInput from "@/components/forms/SelectOrganismeInput.vue";

import Api from "@/modules/axios";

import typeMarcheTraduction from "@/modules/trad_utils";

/**
 * Modale affichée lorsqu'on se connecte sur un compte hors-marché alors qu'un compte marché
 * avec le même UAI est accessible.
 * Exception pour certains marchés où les clients n'ont pas la proposition de changer de compte
 * (il sont censés passer par une autre plateforme, par exemple).
 */
export default {
  name: "ModalVerifyAccount",
  components: {
    Modal,
    ButtonClassic,
    SelectOrganismeInput,
  },
  emits: [
    /**
     * Déclenché lorsqu'on confirme la suppression.
     */
    "confirm",
  ],
  data() {
    return {
      selectedOrganisme: null,
      organismeMarches: [],
      isExcluded: false,
      excluded: [
        "Ile-de-France",
        "Grand Est",
      ],
    };
  },
  computed: {
    ...mapGetters(["organismeActuel", "user"]),
    isSpecificIDF() {
      return this.organismeMarches.some((o) => o.maitre_compta.toLowerCase() === "19c0467");
    },
  },
  mounted() {
    const alreadyAccepted = localStorage.getItem("sessionFondsPropresAccept") === this.user.session;
    const uai = this.organismeActuel?.uai;

    if (!alreadyAccepted && uai && !this.organismeActuel.marches.length) {
      localStorage.setItem("sessionFondsPropresAccept", this.user.session);
      this.$store.dispatch(
        "fetchOrganismes",
        `&uai=${uai}&id!=${this.organismeActuel.id}`,
      )
        .then((res) => {
          this.organismeMarches = res.data.results.filter((o) => o.marches?.length);

          this.isExcluded = this.organismeMarches.some((org) => org.marches.some(
            ({ libelle }) => this.excluded.includes(libelle),
          ));

          this.organismeMarches = this.organismeMarches.map(
            (org) => ({
              label: org.nom_complet,
              value: org.nom_complet,
              maitre_compta: org.maitre_compta,
              id: org.id,
              type: org.type,
            }),
          );

          if (this.organismeMarches.length) {
            this.$set(this.organismeActuel, "fonds_propres", true);
            this.selectedOrganisme = this.organismeMarches[0];
            this.$modal.show("modal_verify_account");
          }
        });
    }
  },
  methods: {
    typeMarcheTraduction,
    /**
     * Change le compte par celui du select.
     */
    confirm() {
      if (this.selectedOrganisme?.id !== this.organismeActuel?.id) {
        localStorage.removeItem("sessionFondsPropresAccept");
        Api().post("/switch/", { organisme: this.selectedOrganisme.id })
          .then((res) => {
            this.$store.commit("setOrganismeActuel", res.data);
            window.location.reload();
          });
      }
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#modal_verify_account {
  .select-org-container {
    padding: 0 var.$space-tiny;
    .select-classic { display: block; }
  }

  .modal-body { overflow: unset; }

  .button-classic:first-child { margin-right: var.$space-tiny; }
}
</style>
