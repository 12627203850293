<template>
  <MainHeader
    :is-avatar-popover-open="isAvatarPopoverOpen"
    :dark-mode="darkMode"
    :forbidden-mode="disabled"
    :expanded-sidebar="internalExpandedSidebar"
    :notifications="notifications"
    :infos-user="infosUser"
    :user="user"
    @toggle-sidebar="internalExpandedSidebar = !internalExpandedSidebar"
    @clear-notifications="clearNotifications()"
    @disconnect="disconnect()"
    @read-notification="readNotification($event)"
    @avatar-popover-toggle="isAvatarPopoverOpen = $event"
  >
    <template #search-form>
      <MainSearchForm
        :disabled="disabled"
      />
    </template>
    <template #avatar-button-options>
      <li class="avatar-option">
        <button
          class="button option-btn"
          @click="redirectEtab()"
        >
          <span class="text text-medium">{{ $t('action.voir-parametre-mon-etablissement') }}</span>
          <IconEstablishment class="icon" />
        </button>
      </li>
    </template>
    <template #avatar-button-other>
      <ButtonService
        :service="service"
        color="white"
      />
    </template>
    <template
      v-if="!disabled"
      #additional-left-avatar
    >
      <ButtonGroupHeader :dark-mode="darkMode" />
      <SwitchOrganisme
        v-if="!disabled"
        class="header-select"
        inline
      >
        <template #after-option-item="{ option: org, }">
          <Tag
            v-if="org.type"
            :tag-name="org.type"
          />
        </template>
      </SwitchOrganisme>
    </template>
  </MainHeader>
</template>

<script>
import { MainHeader, Tag, ButtonService } from "@lde/core_lde_vue";

import { mapGetters } from "vuex";
import ButtonGroupHeader from "@/components/buttons/ButtonGroupHeader.vue";
import SwitchOrganisme from "@/components/forms/SwitchOrganisme.vue";
import MainSearchForm from "@/components/search/MainSearchForm.vue";

import IconEstablishment from "@/components/icons/IconEstablishment.vue";

import config from "@/config";
import Sentry from "@/modules/sentry";
import Api from "@/modules/axios";

/**
 * Affiche le header du site. Celui-ci regroupe de nombreux composants accessibles de partout.
 */
export default {
  name: "Header",
  components: {
    MainHeader,
    Tag,
    ButtonService,
    ButtonGroupHeader,
    SwitchOrganisme,
    MainSearchForm,
    IconEstablishment,
  },
  props: {
    /**
     * Si le site est en dark mode.
     */
    darkMode: {
      type: Boolean,
      default: false,
    },
    /**
     * Désactive les actions possibles.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Agrandit ou réduit le menu de gauche.
     */
    expandedSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fetchingInterval: null,
      isAvatarPopoverOpen: false,
      notifications: {},
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "organismeActuel",
      "isHorsMarche",
      "hasPerm",
      "hasPerms",
      "country",
    ]),
    internalExpandedSidebar: {
      get() {
        return this.expandedSidebar;
      },
      set(newVal) {
        this.$emit("change-expanded-sidebar", newVal);
      },
    },
    infosUser() {
      let role = "";
      switch (this.user.role) {
        case "Opérateur LDE":
          role = this.$t("role.operateur-lde");
          break;
        case "COR_NUM_LIB":
          role = this.$t("role.cor-num-lib");
          break;
        case "Donneur d'ordre":
          role = this.$t("role.donneur-dordre");
          break;
        case "Valideur":
        case "Valideur hors-marché":
          role = this.$t("role.valideur");
          break;
        case "Prescripteur":
          role = this.$t("role.prescripteur");
          break;
        default:
          role = this.$t("role.consultant");
          break;
      }

      return [
        { label: this.$t("general.identifiant"), value: this.user.username },
        { label: this.$t("general.uai"), value: this.organismeActuel?.uai },
        { label: this.$t("general.profil"), value: role },
      ];
    },
    service() {
      return {
        url: config.url.hub,
        nom: this.$t("general.retour-vers-hub"),
        // eslint-disable-next-line import/no-dynamic-require, global-require
        image: require("@lde/core_lde_vue/dist/assets/media/logos/logo_lde_squares.svg"),
      };
    },
  },
  mounted() {
    this.fetchNewNotifications();
    // Lorsque l'onglet du site est actif, on active la recherche des notifications
    // (qui se fait toutes les xx secondes)
    window.onfocus = () => {
      this.startFetchingNotifications();
    };
    // Lorsque l'onglet n'est plus actif (l'utilisateur a changé d'onglet dans son navigateur),
    // on arrête de rechercher les notifications
    window.onblur = () => {
      this.stopFetchingNotifications();
    };
  },
  methods: {
    /**
     * Récupère les notifications toutes les 15 secondes.
     */
    startFetchingNotifications() {
      const interval = setInterval(() => {
        this.fetchNewNotifications();
      }, 15000);
      this.fetchingInterval = interval;
    },
    /**
     * Reset le timer de la requête.
     */
    stopFetchingNotifications() {
      clearInterval(this.fetchingInterval);
    },
    /**
     * Récupère les notifications.
     */
    fetchNewNotifications() {
      Api().get("/notification/?date_lecture__isnull=true")
        .then(({ data }) => {
          this.notifications = data;
          data.results.forEach((element) => {
            // Remplace url_cible par url pour utilisation dans le composant Notification
            element.url = element.url_cible;
            element.titre = this.$t(`notification.${element.titre}`);
            delete element.url_cible;
          });
        });
    },
    /**
     * Définit la notification comme "vue".
     * @param {Object} notif Notification ciblée.
     */
    readNotification(notif) {
      this.isAvatarPopoverOpen = false;

      return Api()
        .patch(`notification/${notif.id}/`, { date_lecture: new Date().toISOString() })
        .then(() => {
          this.fetchNewNotifications();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    /**
    * Supprime toutes les notifications.
    */
    clearNotifications() {
      Api().delete("/notification/all/").then(({ data }) => {
        this.notifications = data;
      });
    },
    /**
     * Déconnecte l'utilisateur.
     */
    disconnect() {
      Sentry.unsetScope();
      Sentry.close();
      localStorage.removeItem("current_organisme_id");
      window.location = config.logoutUrl;
    },
    /**
     * Redirige vers la page des établissements.
     */
    redirectEtab() {
      const name = "etablissements_actuel";
      if (this.$route.name !== name) {
        this.$router.push({ name });
      }
      this.isAvatarPopoverOpen = false;
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;
@use "~@lde/core_lde_vue/dist/assets/styles/_fonts.scss" as font;

#main_header {
  .button-service {
    flex-direction: row-reverse;
    margin-bottom: var.$space-x-tiny;
    padding: var.$space-x-tiny var.$space-tiny;
    box-shadow: none;

    .name { @include font.text-regular; }

    img {
      margin-right: 0;
      margin-left: var.$space-x-tiny;
      width: var.$space-medium;
      height: var.$space-medium;
    }
  }

  > .button-classic:first-of-type {
    margin-right: var.$space-tiny;
  }
}
</style>
